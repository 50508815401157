"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDownloadExcel = void 0;
const react_1 = require("react");
const lib_1 = require("../lib");
function useDownloadExcel({ currentTableRef, filename, sheet, }) {
    const [payload, setPayload] = (0, react_1.useState)({});
    (0, react_1.useEffect)(() => {
        setPayload({
            currentTableRef,
            filename,
            sheet,
        });
    }, [currentTableRef, filename, sheet]);
    return (0, react_1.useMemo)(() => (0, lib_1.excel)(payload), [payload]);
}
exports.useDownloadExcel = useDownloadExcel;
