"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadExcel = exports.excel = void 0;
const utils = __importStar(require("./utils"));
function valEnv() {
    if (!document) {
        if ((process === null || process === void 0 ? void 0 : process.env.NODE_ENV) !== "production") {
            console.error("Failed to access document object");
        }
        return false;
    }
    return true;
}
function download(fileName, context) {
    const element = window.document.createElement("a");
    element.href =
        utils.uri + utils.base64(utils.format(utils.template, context));
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    return true;
}
function getTable(currentTableRef, tablePayload) {
    if (currentTableRef) {
        const cloneTable = currentTableRef.cloneNode(true);
        return cloneTable.outerHTML;
    }
    if (tablePayload)
        return utils.createTable(tablePayload);
    console.error("currentTableRef or tablePayload does not exist");
}
function handleDownload({ fileName, sheet, tablePayload, }, currentTableRef) {
    if (!valEnv())
        return false;
    const table = getTable(currentTableRef, tablePayload);
    const context = {
        worksheet: sheet || "Worksheet",
        table,
    };
    return download(fileName, context);
}
exports.downloadExcel = handleDownload;
function excel({ currentTableRef, filename, sheet }) {
    function onDownload() {
        if (!valEnv())
            return false;
        const table = getTable(currentTableRef);
        const fileName = `${filename}.xls`;
        const context = {
            worksheet: sheet || "Worksheet",
            table,
        };
        return download(fileName, context);
    }
    return { onDownload };
}
exports.excel = excel;
